import React, { useState, useEffect, FC, Fragment } from 'react';
import { changeUserHarbour, getLandingById, getWeightNotesReport } from '../../../../../Services/LondunarkerfiAPIService';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import User from '../../../../../Models/UserModels/User';
import { InputAdornment, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import WeightNote from '../../../../../Models/WeightNoteModels/WeightNote';
import dayjs from 'dayjs';
import Landing from '../../../../../Models/LandingModels/Landing';
import UserHarbours from '../../../../../Models/UserModels/UserHarbours';
import HarbourAutoComplete from '../../../../AutoComplete/HarbourAutoComplete';
import ChangeHarbourBody from '../../../../../Models/UserModels/ChangeHarbourBody';
import { logError } from '../../../../../Helpers/LogError';
import API_PAGINATION_SIZE from '../../../../../Constants/PaginationSize';

interface WeightNoteReportsProps {
  user: User;
  userHarbours: UserHarbours[];
  toggleOpen: () => void;
  setSelectedLanding: (landing: Landing, newLandingId?: number, landings?: Landing[], newWeightNoteId?: number) => void;
  refetchLandings?: () => void;
}

const WeightNoteReport: FC<WeightNoteReportsProps> = (props: WeightNoteReportsProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [weightNotes, setWeightNotes] = useState<WeightNote[]>([]);
  const [selectedHarbourFilter, setSelectedHarbourFilter] = useState<UserHarbours | null>(props.userHarbours.find((harbour) => harbour.id === props.user.userPickedHarbour) || null);
  const [search, setSearch] = useState<string>('');
  const [isClosedFilter, setIsClosedFilter] = useState<boolean | null>(null);
  const SCROLL_TOLERANCE = 5;

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      fetchWeightNotes(1);
    }, 500);

    return () => clearTimeout(debounceTimer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, selectedHarbourFilter?.id, isClosedFilter]);

  const fetchWeightNotes = async (page: number) => {
    try {
      setLoading(true);
      if (search?.length === 0) {
        const response = await getWeightNotesReport(selectedHarbourFilter?.id, undefined, page, isClosedFilter ?? undefined);
        setWeightNotes((prevWeightNotes) => (page === 1 ? response : [...prevWeightNotes, ...response]));
        setLoading(false);
        return;
      }
      const response = await getWeightNotesReport(selectedHarbourFilter?.id, search, page, isClosedFilter ?? undefined);
      setWeightNotes((prevWeightNotes) => (page === 1 ? response : [...prevWeightNotes, ...response]));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      logError(error);
    }
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (e.currentTarget) {
      const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;
      const bottom = Math.abs(scrollHeight - scrollTop - clientHeight) <= SCROLL_TOLERANCE;
      if (bottom && !loading) {
        fetchWeightNotes(Math.round(weightNotes?.length / API_PAGINATION_SIZE) + 1);
      }
    }
  };

  const handleSearchChange = (newSearch: string) => {
    setSearch(newSearch);
  };

  const handleIsClosedFilterChange = (event: React.MouseEvent<HTMLElement>, newValue: boolean | null) => {
    setIsClosedFilter(newValue);
  };

  const handleWeightNoteSelection = async (weightNote: WeightNote) => {
    // if user had previously selected harbour that is not the same as the harbour filter, we need to change harbour on selection.
    if (selectedHarbourFilter?.id !== props.user.userPickedHarbour && selectedHarbourFilter) {
      const changeUserHarbourBody: ChangeHarbourBody = {
        changeToHarbourID: selectedHarbourFilter.id,
      };
      props.user.userPickedHarbour = selectedHarbourFilter.id;
      props.user.userPickedHarbourName = selectedHarbourFilter.harbourName;
      await changeUserHarbour(changeUserHarbourBody);
      if (props.refetchLandings) {
        props.refetchLandings();
      }
    }
    const landing = await getLandingById(weightNote.landingId);
    props.setSelectedLanding(landing, undefined, undefined, weightNote.id);
    props.toggleOpen();
  }

  const handleHarbourChange = async (harbour: UserHarbours | null) => {
    setSelectedHarbourFilter(harbour);
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID' },
    { field: 'gear', headerName: 'Veiðarfæri', flex: 1, valueGetter: (params) => params.row.gear?.name, resizable: false },
    { field: 'buyer', headerName: 'Kaupandi', flex: 2, valueGetter: (params) => params.row.buyer?.name, resizable: false },
    { field: 'receiver', headerName: 'Viðtakandi', flex: 2, valueGetter: (params) => params.row.receiver?.name, resizable: false },
    { field: 'totalQuantity', headerName: 'Magn', flex: 1, type: 'number', resizable: false },
    { field: 'isClosed', headerName: 'Lokuð', type: 'boolean', flex: 1, resizable: false },
    { field: 'noteNumber', headerName: 'Nótunúmer', type: 'string', flex: 1, resizable: false },
    { field: 'weightNoteDate', headerName: 'Dagsetning', flex: 1, valueGetter: (params) => dayjs(params.row.weightNoteRegistered).format('DD.MM.YYYY'), resizable: false },
    { field: 'isCredit', headerName: 'Kreditnóta', type: 'boolean', flex: 1, resizable: false }
  ];

  return (
    <Fragment>
      <div style={{
        display: 'flex',
        gap: '16px',
        alignItems: 'center',
        marginBottom: '20px',
        flexWrap: 'wrap'
      }}>
        {props.userHarbours.length > 1 && (
          <HarbourAutoComplete
            onHarbourChange={handleHarbourChange}
            selectedHarbour={selectedHarbourFilter || undefined}
            harbours={props.userHarbours}
            sx={{ minWidth: '100%', flex: 2 }}
            user={props.user}
          />
        )}
        <TextField
          id="search-weightNote"
          label="Leita..."
          type="search"
          variant="standard"
          size="small"
          value={search}
          onChange={(e) => handleSearchChange(e.target.value)}
          autoFocus
          sx={{ minWidth: '200px', flex: 1 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <ToggleButtonGroup
          value={isClosedFilter}
          exclusive
          onChange={handleIsClosedFilterChange}
          aria-label="Filter by isClosed"
          size="small"
          sx={{ height: '40px' }}
        >
          <ToggleButton color='primary' value={true} aria-label="Closed">
            Lokaðar
          </ToggleButton>
          <ToggleButton color='primary' value={false} aria-label="Open">
            Opnar
          </ToggleButton>
        </ToggleButtonGroup>
      </div>

      <div style={{ overflowY: 'auto', height: 'calc(100vh - 250px)' }} onScroll={handleScroll}>
        <DataGrid
          rows={weightNotes}
          columns={columns}
          loading={loading}
          autoHeight
          onRowClick={(row) => { handleWeightNoteSelection(row.row) }}
          columnVisibilityModel={{ id: false }}
          sx={{
            '& .MuiDataGrid-cell:focus': {
              outline: 'none'
            },
            border: 'none',
            '& .MuiDataGrid-row:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)'
            }
          }}
        />
      </div>
    </Fragment>
  );
};

export default WeightNoteReport;
