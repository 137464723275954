import React, { useState, useEffect, FC, Fragment } from 'react';
import { getTransactionsReport } from '../../../../../Services/LondunarkerfiAPIService';
import { DataGrid, GridColDef, GridToolbarContainer } from '@mui/x-data-grid';
import User from '../../../../../Models/UserModels/User';
import Transactions from '../../../../../Models/ReportModels/Transactions';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/is';
import { Autocomplete, InputAdornment, TextField } from '@mui/material';
import Ship from '../../../../../Models/ShipModels/Ship';
import Contact from '../../../../../Models/ContactModels/Contact';
import FishingStock from '../../../../../Models/CatchRegistrationModels/FishingStock';
import Gear from '../../../../../Models/WeightNoteModels/Gear';
import PhishingIcon from '@mui/icons-material/Phishing';
import SetMealIcon from '@mui/icons-material/SetMeal';
import ExcelGenerator from '../../../../ExcelGenerator/ExcelGenerator';
import ContactAutoComplete from '../../../../AutoComplete/ContactAutoComplete';
import ShipAutoComplete from '../../../../AutoComplete/ShipAutoComplete';
import { logError } from '../../../../../Helpers/LogError';
import API_PAGINATION_SIZE from '../../../../../Constants/PaginationSize';

interface TransactionsReportsProps {
  user: User;
  toggleOpen: () => void;
  fishingGears: Gear[];
  fishingStocks: FishingStock[];
}

const TransactionsReport: FC<TransactionsReportsProps> = (props: TransactionsReportsProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dateFrom, setDateFrom] = useState<Dayjs | null>(dayjs().subtract(30, 'day'));
  const [dateTo, setDateTo] = useState<Dayjs | null>(dayjs());
  const [Transactions, setTransactions] = useState<Transactions[]>([]);
  const [ship, setShip] = useState<Ship | null>(null);
  const [selectedReciever, setSelectedReciever] = useState<Contact | null>(null);
  const [selectedBuyer, setSelectedBuyer] = useState<Contact | null>(null);
  const [selectedSeller, setSelectedSeller] = useState<Contact | null>(null);
  const [selectedFish, setSelectedFish] = useState<FishingStock | null>(null);
  const [selectedGear, setSelectedGear] = useState<Gear | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [reportData, setReportData] = useState<any[]>([]);
  const [reportLoading, setReportLoading] = useState<boolean>(false);
  const SCROLL_TOLERANCE = 5;

  useEffect(() => {
    fetchCathes(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFrom, dateTo, ship, selectedBuyer, selectedReciever, selectedSeller, selectedFish, selectedGear]);

  const fetchCathes = async (page: number) => {
    try {
      setLoading(true);
      if (props.user?.userPickedHarbour) {
        const formattedDateFrom = dateFrom?.format('DD.MM.YYYY');
        const formattedDateTo = dateTo?.format('DD.MM.YYYY');
        const response = await getTransactionsReport(
          props.user?.userPickedHarbour,
          page,
          formattedDateFrom,
          formattedDateTo,
          ship?.shipRegistrationNumber,
          selectedBuyer?.ssn,
          selectedReciever?.ssn,
          selectedSeller?.ssn,
          selectedFish?.id,
          selectedGear?.id);
        setTransactions((prevWeightNotes) => (page === 1 ? response : [...prevWeightNotes, ...response]));
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      logError(error);
    }
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (e.currentTarget) {
      const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;
      const bottom = Math.abs(scrollHeight - scrollTop - clientHeight) <= SCROLL_TOLERANCE;
      if (bottom && !loading) {
        fetchCathes(Math.round(Transactions?.length / API_PAGINATION_SIZE) + 1);
      }
    }
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', type: 'number' },
    { field: 'landingDate', headerName: 'Dagsetning', valueGetter: (params) => dayjs(params.row.landingDate).format('DD.MM.YYYY'), width: 150, resizable: false },
    { field: 'shipName', headerName: 'Skip', width: 150, resizable: false },
    { field: 'shipRegistrationNumber', headerName: 'Skip nr.', type: 'number', width: 120, resizable: false },
    { field: 'gearName', headerName: 'Veiðarfæri', width: 140, resizable: false },
    { field: 'sellerName', headerName: 'Seljandi', width: 175, resizable: false },
    { field: 'buyerName', headerName: 'Kaupandi', width: 175, resizable: false },
    { field: 'receiverName', headerName: 'Viðtakandi', width: 175, resizable: false },
    { field: 'fishName', headerName: 'Fisktegund', width: 140, resizable: false },
    { field: 'countFish', headerName: 'Magn', type: 'number', width: 110, resizable: false },
  ];

  const handleDateFromChange = (date: Dayjs | null) => {
    setDateFrom(date);
  };

  const handleDateToChange = (date: Dayjs | null) => {
    setDateTo(date);
  };

  const handleShipChange = (selectedShip: Ship | null) => {
    setShip(selectedShip);
  };

  const handleBuyerChange = (params: Contact | null) => {
    setSelectedBuyer(params);
  };

  const handleRecieverChange = (params: Contact | null) => {
    setSelectedReciever(params);
  };

  const handleSellerChange = (params: Contact | null) => {
    setSelectedSeller(params);
  };

  const handleFishingGearChange = (params: Gear | null) => {
    setSelectedGear(params);
  };

  const handleInputGearChange = (event: React.ChangeEvent<object>, value: string) => {
    // Filter gears
    const filteredGears = props.fishingGears.filter((gear) => {
      return gear.name.toLowerCase().includes(value.toLowerCase());
    });

    if (filteredGears?.length === 1) {
      // If there's only one matching gear, set it as the value.
      handleFishingGearChange(filteredGears[0]);
    }
  };

  const handleFishingStockChange = (params: FishingStock | undefined) => {
    setSelectedFish(params || null);
  };

  const handleInputStockChange = (event: React.ChangeEvent<object>, value: string) => {
    // Filter stocks
    const filteredStocks = props.fishingStocks.filter((stock) => {
      return stock.name.toLowerCase().includes(value.toLowerCase());
    });

    if (filteredStocks?.length === 1) {
      // If there's only one matching stock, set it as the value.
      handleFishingStockChange(filteredStocks[0]);
    }
  };

  const fetchReportData = async () => {
    try {
      setReportLoading(true);
      const reportData = await getTransactionsReport(
        props.user?.userPickedHarbour ?? 0,
        undefined,
        dateFrom?.format('DD.MM.YYYY'),
        dateTo?.format('DD.MM.YYYY'),
        ship?.shipRegistrationNumber,
        selectedBuyer?.ssn,
        selectedReciever?.ssn,
        selectedSeller?.ssn,
        selectedFish?.id,
        selectedGear?.id
      );
      setReportLoading(false);
      return reportData;
    } catch (error) {
      setReportLoading(false);
      logError(error);
      return [];
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchReportData();
      setReportData(data);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFrom, dateTo, ship, selectedBuyer, selectedReciever, selectedSeller, selectedFish, selectedGear]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <ExcelGenerator data={reportData} disabled={reportLoading} fileName='hreyfingarlisti' />
      </GridToolbarContainer>
    );
  }

  return (
    <Fragment>
      <div style={{ width: '100%', display: 'flex', gap: '1em', flexWrap: 'wrap' }}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='is'>
          <DatePicker
            sx={{ flex: '1', minWidth: '15em' }}
            disabled={loading}
            label='Dagsetning frá'
            value={dateFrom}
            onChange={(newValue: Dayjs | null) => handleDateFromChange(dayjs(newValue))}
            format="DD.MM.YYYY"
            maxDate={dayjs()}
          />
          <DatePicker
            sx={{ flex: '1', minWidth: '15em' }}
            disabled={loading}
            label='Dagsetning til'
            value={dateTo}
            onChange={(newValue: Dayjs | null) => handleDateToChange(dayjs(newValue))}
            format="DD.MM.YYYY"
            maxDate={dayjs()}
          />
        </LocalizationProvider>
        <ShipAutoComplete onShipChange={handleShipChange} selectedShip={ship} sx={{ flex: '1', minWidth: '15em' }} autofocus={false} />
        <Autocomplete
          sx={{ flex: '1', minWidth: '15em' }}
          disablePortal
          onInputChange={handleInputGearChange}
          disabled={loading}
          options={props.fishingGears}
          autoSelect={true}
          autoHighlight
          value={selectedGear}
          slotProps={{
            popper: {
              disablePortal: false,
            }
          }}
          onChange={(event, value) => handleFishingGearChange(value)}
          getOptionLabel={(option) => option.name || ""}
          renderInput={(params) =>
            <TextField
              label="Veiðarfæri"
              {...params}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position='start'>
                    <PhishingIcon />
                  </InputAdornment>
                )
              }}
            />}
        />

        <Autocomplete
          sx={{ flex: '1', minWidth: '15em' }}
          disablePortal
          autoSelect={true}
          onInputChange={handleInputStockChange}
          disabled={loading}
          options={props.fishingStocks}
          getOptionLabel={(option) => option.name || ""}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          value={selectedFish || null}
          slotProps={{
            popper: {
              disablePortal: false,
            }
          }}
          onChange={(event, value) => handleFishingStockChange(value || undefined)}
          renderInput={(params) =>
            <TextField
              label="Veiðistofn"
              color="primary"
              {...params}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SetMealIcon />
                  </InputAdornment>
                )
              }}
            />}
        />
        <ContactAutoComplete type='Kaupandi' smallInputField={true} onContactChange={handleBuyerChange} selectedContact={selectedBuyer} autofocus={false} sx={{ flex: '1', minWidth: '15em' }} />
        <ContactAutoComplete type='Viðtakandi' smallInputField={true} onContactChange={handleRecieverChange} selectedContact={selectedReciever} autofocus={false} sx={{ flex: '1', minWidth: '15em' }} />
        <ContactAutoComplete type='Seljandi' smallInputField={true} onContactChange={handleSellerChange} selectedContact={selectedSeller} autofocus={false} sx={{ flex: '1', minWidth: '15em' }} />
      </div>
      <div style={{ overflowY: 'auto' }} onScroll={handleScroll}>
        <DataGrid
          rows={Transactions}
          columns={columns}
          loading={loading}
          autoHeight
          columnVisibilityModel={{ id: false }}
          slots={{
            toolbar: CustomToolbar,
          }}
        />
      </div>
    </Fragment >
  );
};

export default TransactionsReport;
