import React, { useState, useEffect, FC, Fragment } from 'react';
import { changeUserHarbour, getCatchesReport, getLandingById, getWeightNoteById } from '../../../../../Services/LondunarkerfiAPIService';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import User from '../../../../../Models/UserModels/User';
import { InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import dayjs from 'dayjs';
import Landing from '../../../../../Models/LandingModels/Landing';
import WeightReport from '../../../../../Models/ReportModels/WeightReport';
import UserHarbours from '../../../../../Models/UserModels/UserHarbours';
import ChangeHarbourBody from '../../../../../Models/UserModels/ChangeHarbourBody';
import HarbourAutoComplete from '../../../../AutoComplete/HarbourAutoComplete';
import { logError } from '../../../../../Helpers/LogError';
import API_PAGINATION_SIZE from '../../../../../Constants/PaginationSize';

interface CatchReportsProps {
  user: User;
  refetchLandings?: () => void;
  userHarbours: UserHarbours[];
  toggleOpen: () => void;
  setSelectedLanding: (landing: Landing, newLandingId?: number, landings?: Landing[], newWeightNoteId?: number) => void;
}

const CatchReport: FC<CatchReportsProps> = (props: CatchReportsProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [catches, setCatches] = useState<WeightReport[]>([]);
  const [selectedHarbourFilter, setSelectedHarbourFilter] = useState<UserHarbours | null>(props.userHarbours.find((harbour) => harbour.id === props.user.userPickedHarbour) || null);
  const [search, setSearch] = useState<string>('');
  const SCROLL_TOLERANCE = 5;

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      if (search?.length === 0) {
        fetchCathes(1);
      } else {
        fetchCathes(1);
      }
    }, 500);

    return () => clearTimeout(debounceTimer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, selectedHarbourFilter?.id]);

  const fetchCathes = async (page: number) => {
    try {
      setLoading(true);
      if (search?.length === 0) {
        const response = await getCatchesReport(selectedHarbourFilter?.id, undefined, page);
        setCatches((prevWeightNotes) => (page === 1 ? response : [...prevWeightNotes, ...response]));
        setLoading(false);
        return;
      }
      const response = await getCatchesReport(selectedHarbourFilter?.id, search, page);
      setCatches((prevWeightNotes) => (page === 1 ? response : [...prevWeightNotes, ...response]));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      logError(error);
    }
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (e.currentTarget) {
      const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;
      const bottom = Math.abs(scrollHeight - scrollTop - clientHeight) <= SCROLL_TOLERANCE;
      if (bottom && !loading) {
        fetchCathes(Math.round(catches?.length / API_PAGINATION_SIZE) + 1);
      }
    }
  };

  const handleSearchChange = (newSearch: string) => {
    setSearch(newSearch);
  };

  const handleHarbourChange = async (harbour: UserHarbours | null) => {
    setSelectedHarbourFilter(harbour);
  };

  const handleCatchSelection = async (weight: WeightReport) => {
    const weightNote = await getWeightNoteById(weight.weightNoteId);
    const landing = await getLandingById(weightNote.landingId);
    // if user had previously selected harbour that is not the same as the harbour filter, we need to change harbour on selection.
    if (selectedHarbourFilter?.id !== props.user.userPickedHarbour && selectedHarbourFilter) {
      const changeUserHarbourBody: ChangeHarbourBody = {
        changeToHarbourID: selectedHarbourFilter.id,
      };
      props.user.userPickedHarbour = selectedHarbourFilter.id;
      props.user.userPickedHarbourName = selectedHarbourFilter.harbourName;
      await changeUserHarbour(changeUserHarbourBody);
      if (props.refetchLandings) {
        props.refetchLandings();
      }
    }
    props.setSelectedLanding(landing, landing.id, undefined, weightNote.id);
    props.toggleOpen();
  }

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID' },
    { field: 'landingTypeString', flex: 1, headerName: 'Landanagerð', resizable: false },
    { field: 'ship', headerName: 'Skip (nr.)', flex: 2, valueGetter: (params) => params.row.ship?.name + " (" + params.row.ship?.shipRegistrationNumber + ")", resizable: false },
    { field: 'destiny', headerName: 'Afdrif', flex: 1, valueGetter: (params) => params.row.destiny?.name, resizable: false },
    { field: 'condition', headerName: 'Ástand', flex: 1, valueGetter: (params) => params.row.condition?.name, resizable: false },
    { field: 'fishingArea', headerName: 'Veiðisvæði', flex: 1, valueGetter: (params) => params.row.fishingArea?.name, resizable: false },
    { field: 'fishingStock', headerName: 'Veiðistofn', flex: 1, valueGetter: (params) => params.row.fishingStock?.name, resizable: false },
    { field: 'storageMethod', headerName: 'Geymsluaðferð', flex: 1, valueGetter: (params) => params.row.storageMethod?.name, resizable: false },
    { field: 'quantity', headerName: 'Magn', type: 'string', flex: 1, resizable: false },
    { field: 'registered', headerName: 'Dagsetning', flex: 1, valueGetter: (params) => dayjs(params.row.registered).format('DD.MM.YYYY'), resizable: false },
    { field: 'underKilogramm', headerName: 'Undirmál', flex: 1, type: 'boolean', resizable: false },
    { field: 'buyer', headerName: 'Kaupandi', flex: 1, valueGetter: (params) => params.row.buyer?.name, resizable: false }
  ];

  return (
    <Fragment>
      {props.userHarbours.length > 1 && (
        <HarbourAutoComplete
          onHarbourChange={handleHarbourChange}
          selectedHarbour={selectedHarbourFilter || undefined}
          harbours={props.userHarbours}
          sx={{ width: '100%' }}
          user={props.user}
        />
      )}
      <TextField
        id="search-catches"
        label="Leita..."
        type="search"
        variant="standard"
        value={search}
        onChange={(e) => handleSearchChange(e.target.value)}
        autoFocus
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <div style={{ overflowY: 'auto' }} onScroll={handleScroll}>
        <DataGrid
          rows={catches}
          columns={columns}
          loading={loading}
          autoHeight
          onRowClick={(row) => { handleCatchSelection(row.row) }}
          columnVisibilityModel={{ id: false }}
        />
      </div>
    </Fragment>
  );
};

export default CatchReport;
