import React from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import { IconButton, Tooltip } from '@mui/material';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

interface ExcelGeneratorProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
  disabled: boolean;
  fileName: string;
}

const ExcelGenerator: React.FC<ExcelGeneratorProps> = (props) => {
  const handleGenerateExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    // Add headers based on keys from the first object in the data array
    if (props.data.length > 0) {
      worksheet.columns = Object.keys(props.data[0]).map((key) => ({
        header: key,
        key: key,
        width: 20,
      }));
    }

    // Add rows
    props.data.forEach((row) => {
      worksheet.addRow(row);
    });

    // Generate the Excel file and trigger download
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, `${props.fileName}.xlsx`);
  };

  return (
    <Tooltip title="Sækja allar raðir" arrow>
      <span>
        <IconButton
          size="small"
          color="primary"
          aria-label="sækja skjal"
          onClick={handleGenerateExcel}
          disabled={props.disabled}
        >
          <DownloadIcon />
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default ExcelGenerator;
