import { FC, useEffect, useMemo, useState } from "react";
import Email from "../../../../Models/EmailModels/Email";
import { Box, Button, Dialog, IconButton, InputAdornment, Tab, Tabs, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import { deleteEmail, deleteEmailGroup, getEmailGroups, getEmails } from "../../../../Services/LondunarkerfiAPIService";
import User from "../../../../Models/UserModels/User";
import { useConfirm } from "material-ui-confirm";
import EmailGroup from "../../../../Models/EmailModels/EmailGroup";
import EmailGroupModal from "./Components/EmailGroupModal";
import EmailFormModal from "./Components/EmailFormModal";
import CircularProgress from '@mui/material/CircularProgress';
import { MobilePaperComponent, PaperComponent } from "../../../Paper/CustomPaper";
import { DataGrid, GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { logError } from "../../../../Helpers/LogError";

interface EmailModalProps {
  open: boolean;
  toggleOpen: () => void;
  showSnackbar: (message: string, severity: string) => void;
  user: User;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  tabValue: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, tabValue, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={tabValue !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {tabValue === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

/**
 * Functional component for email modal
 * @param {EmailModalProps} props 
 * @returns {JSX} renders the email modal
 * 
 * Responsible for rendering tabs for single emails and email groups along with CRUD operations for both.
 */

const EmailModal: FC<EmailModalProps> = (props: EmailModalProps) => {
  const [tabValue, setTabValue] = useState(0);
  const [selectedEmailGroup, setSelectedEmailGroup] = useState<EmailGroup>();
  const [selectedEmail, setSelectedEmail] = useState<Email | null>(null);
  const [emailsLoading, setEmailsLoading] = useState<boolean>(false);
  const [emailGroupsLoading, setEmailGroupsLoading] = useState<boolean>(false);
  const [openEmailGroupModal, setOpenEmailGroupModal] = useState<boolean>(false);
  const [openEmailForm, setOpenEmailForm] = useState<boolean>(false);
  const confirm = useConfirm();
  const [emails, setEmails] = useState<Email[]>([]);
  const [emailGroups, setEmailGroups] = useState<EmailGroup[]>([]);
  const [filteredEmails, setFilteredEmails] = useState<Email[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (props.open) {
      fetchEmails();
      fetchEmailGroups();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  const fetchEmails = async () => {
    try {
      setEmailsLoading(true);
      if (props.user) {
        const emails = await getEmails(props.user.userPickedHarbour);
        const sortedEmails = [...emails].sort((a, b) => a.email.localeCompare(b.email));
        setEmails(emails.sort((a, b) => a.email.localeCompare(b.email)));
        setFilteredEmails(sortedEmails);
        setSearchQuery('');
      }
      setEmailsLoading(false);
    } catch (error) {
      setEmailsLoading(false);
      logError(error);
    }
  };

  const handleEmailSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    setFilteredEmails(emails.filter((email) => email.email?.toLowerCase().includes(event.target.value.toLowerCase())));
  };

  const fetchEmailGroups = async () => {
    try {
      setEmailGroupsLoading(true);
      if (props.user) {
        const emailgroups = await getEmailGroups(props.user.userPickedHarbour);
        setEmailGroups(emailgroups);
        setEmailGroupsLoading(false);
        return emailgroups;
      }
      setEmailGroupsLoading(false);
    } catch (error) {
      setEmailGroupsLoading(false);
      logError(error);
      return [];
    }
  };

  const handleClose = () => {
    props.toggleOpen();
    setSearchQuery('');
    const copiedEmails = [...emails];
    setFilteredEmails(copiedEmails.sort((a, b) => a.email.localeCompare(b.email)));
    setTabValue(0);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleDeleteEmailGroup = (groupId: number) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    confirm({
      title: 'Ertu alveg viss?',
      description: `Þetta mun eyða hópnum og tenginu netfanga við þennan hóp!.`,
      confirmationText: 'Eyða',
      cancellationText: 'Hætta við',
      confirmationButtonProps: { variant: 'contained', color: 'error' },
      cancellationButtonProps: { variant: 'outlined' },
    })
      .then(() => delEmailGroup(groupId))
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => { });
  };

  const handleDeleteEmailAddress = (emailId: number) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    const email = emails.find((email) => email.id === emailId);
    confirm({
      title: 'Ertu alveg viss?',
      description: `Þetta mun eyða netfanginu: ${email?.email}.`,
      confirmationText: 'Eyða',
      cancellationText: 'Hætta við',
      confirmationButtonProps: { variant: 'contained', color: 'error' },
      cancellationButtonProps: { variant: 'outlined' },
    })
      .then(() => delEmail(emailId))
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => { });
  };

  const delEmailGroup = async (groupId: number) => {
    setEmailGroupsLoading(true);
    try {
      await deleteEmailGroup(groupId);
      fetchEmailGroups();
      props.showSnackbar('Hóp eytt!', 'success');
      setEmailGroupsLoading(false);
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    catch (error: any) {
      setEmailGroupsLoading(false);
      if (error.response && error.response.data && typeof error.response.data !== 'object') {
        const errorMsg = error.response.data;
        props.showSnackbar(errorMsg, 'error');
      }
      else {
        logError(error);
        props.showSnackbar('Óvænt villa kom upp!', 'error');
      }
    }
  };

  const delEmail = async (emailId: number) => {
    setEmailsLoading(true);
    try {
      await deleteEmail(emailId);
      await fetchEmails();
      props.showSnackbar('Netfangi eytt!', 'success');
      setEmailsLoading(false);
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    catch (error: any) {
      setEmailsLoading(false);
      if (error.response && error.response.data && typeof error.response.data !== 'object') {
        const errorMsg = error.response.data;
        props.showSnackbar(errorMsg, 'error');
      }
      else {
        logError(error);
        props.showSnackbar('Óvænt villa kom upp!', 'error');
      }
    }
  };

  const handleClickGroupRow = (group: EmailGroup) => {
    setSelectedEmailGroup(group);
    toggleOpenGroupModal();
  };

  const handleSingleEmailClick = (email: Email) => {
    setSelectedEmail(email);
    toggleOpenEmailForm();
  }

  const toggleOpenGroupModal = () => {
    setOpenEmailGroupModal(!openEmailGroupModal);
  };

  const toggleOpenEmailForm = () => {
    setOpenEmailForm(!openEmailForm);
  };

  const handleAddGroupClick = () => {
    setSelectedEmailGroup(undefined);
    toggleOpenGroupModal();
  };

  const handleAddEmailAddress = () => {
    setSelectedEmail(null);
    toggleOpenEmailForm();
  };

  const handleRefetchAndSelectEmail = async (groupId: number | null) => {
    const updatedEmailGroup = await fetchEmailGroups();
    setSelectedEmailGroup(updatedEmailGroup?.find((group) => group.id === groupId));
  };

  const handleAddEmailFromGroupModal = () => {
    setSelectedEmail(null);
    setTabValue(0);
    toggleOpenGroupModal();
    toggleOpenEmailForm();
  };

  const resetEmailGroup = () => {
    setSelectedEmailGroup(undefined);
  }

  const emailColumns = useMemo<GridColDef<Email>[]>(() => [
    { field: 'id', headerName: 'ID', headerAlign: 'left' },
    { field: 'email', headerName: 'Netfang', headerAlign: 'left', flex: 1 },
    { field: 'comment', headerName: 'Athugasemd', headerAlign: 'left' },
    {
      field: 'actions',
      type: 'actions',
      width: 1,
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            color="error"
            disabled={props.user?.role.id === Number(process.env.REACT_APP_ROLE_READ_ID)}
            onClick={handleDeleteEmailAddress(params.id as number)}
          />
        ];
      },
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [emails]
  );

  const emailGroupColumns = useMemo<GridColDef<EmailGroup>[]>(() => [
    { field: 'id', headerName: 'ID', headerAlign: 'left' },
    { field: 'description', headerName: 'Lýsing', headerAlign: 'left', flex: 1 },
    {
      field: 'contact', headerName: 'Aðili', headerAlign: 'left', flex: 1,
      valueGetter: (params) => {
        const contactName = params.row.contact?.name || '';
        return contactName;
      }
    },
    {
      field: 'ship', headerName: 'Skip', headerAlign: 'left', flex: 1,
      valueGetter: (params) => {
        const shipName = params.row.ship?.name && params.row.ship?.shipRegistrationNumber
          ? `${params.row.ship.name} - (${params.row.ship.shipRegistrationNumber})`
          : '';
        return shipName;
      }
    },
    {
      field: 'actions',
      type: 'actions',
      width: 1,
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            color="error"
            disabled={props.user?.role.id === Number(process.env.REACT_APP_ROLE_READ_ID)}
            onClick={handleDeleteEmailGroup(params.id as number)}
          />
        ];
      },
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], []
  );

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      PaperComponent={isMobile || isTablet ? MobilePaperComponent : PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <EmailGroupModal
        user={props.user}
        emails={emails}
        emailGroups={emailGroups}
        selectedEmailGroup={selectedEmailGroup ? selectedEmailGroup : null}
        open={openEmailGroupModal}
        toggleOpen={toggleOpenGroupModal}
        showSnackbar={props.showSnackbar}
        fetchEmailGroups={fetchEmailGroups}
        onRefetchAndSelectEmail={handleRefetchAndSelectEmail}
        handleAddSingleEmail={handleAddEmailFromGroupModal}
        resetEmailGroup={resetEmailGroup}
      />
      <EmailFormModal
        user={props.user}
        emails={emails}
        editEmail={selectedEmail ? selectedEmail : null}
        open={openEmailForm}
        toggleOpen={toggleOpenEmailForm}
        showSnackbar={props.showSnackbar}
        reFetchEmails={fetchEmails} />
      <div id="draggable-dialog-title" style={{ display: 'flex', flexDirection: isMobile || isTablet ? 'column' : 'row', justifyContent: 'space-between', gap: '1em', cursor: 'move' }}>
        <Typography fontWeight='bold' fontSize='1.5em'>
          Stjórna netföngum
        </Typography>
        {props.user?.role.id !== Number(process.env.REACT_APP_ROLE_READ_ID) && tabValue === 1 && (
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            onClick={handleAddGroupClick}
            disabled={emailsLoading || props.user?.role.id === Number(process.env.REACT_APP_ROLE_READ_ID)}
          >
            Nýr hópur
          </Button>
        )}
        {props.user?.role.id !== Number(process.env.REACT_APP_ROLE_READ_ID) && tabValue === 0 && (
          <Button startIcon={<AddIcon />} variant="contained" onClick={handleAddEmailAddress} disabled={emailsLoading || props.user?.role.id === Number(process.env.REACT_APP_ROLE_READ_ID)}>
            Nýtt netfang
          </Button>
        )}
      </div>
      <IconButton sx={{ position: 'absolute', top: 0, right: 0 }} onClick={handleClose}>
        <CancelIcon fontSize='large' />
      </IconButton>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
            <Tab label="Netföng" {...a11yProps(0)} />
            <Tab label="Hópar" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel tabValue={tabValue} index={0} >
          <TextField
            sx={{ width: '100%', paddingBottom: '1em' }}
            id="searc-emails"
            label="Leita..."
            type="search"
            variant='standard'
            value={searchQuery}
            onChange={handleEmailSearch}
            autoFocus
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />

          <Box sx={{ height: '35em', overflowY: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {emailsLoading ? (
              <CircularProgress />
            ) : (
              <DataGrid
                rows={filteredEmails}
                columns={emailColumns}
                columnVisibilityModel={{ id: false, comment: !isMobile || !isTablet }}
                onRowClick={(row) => handleSingleEmailClick(row.row as Email)}
              />
            )}
          </Box>
        </CustomTabPanel>

        <CustomTabPanel tabValue={tabValue} index={1}>
          <Box sx={{ height: '35em', overflowY: 'auto' }}>

            {emailGroupsLoading ? (
              <Typography sx={{ padding: '1em' }} gutterBottom component="div">
                <CircularProgress />
              </Typography>
            ) : (
              <DataGrid
                rows={emailGroups}
                columns={emailGroupColumns}
                columnVisibilityModel={{ id: false }}
                onRowClick={(row) => handleClickGroupRow(row.row as EmailGroup)}
              />
            )}
          </Box>
        </CustomTabPanel>
      </Box>
    </Dialog >
  );
};

export default EmailModal;