import React, { FC, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Dialog, IconButton, Typography, useMediaQuery, useTheme, CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useConfirm } from 'material-ui-confirm';
import CancelIcon from '@mui/icons-material/Cancel';
import { MobilePaperComponent, PaperComponent } from '../../../../../SharedComponents/Paper/CustomPaper';
import WeightNote from '../../../../../Models/WeightNoteModels/WeightNote';
import { getWeightNotesReport } from '../../../../../Services/LondunarkerfiAPIService';
import User from '../../../../../Models/UserModels/User';
import WeightNoteTypes from '../../../../../Constants/WeightNoteTypes';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PhishingIcon from '@mui/icons-material/Phishing';
import CommentIcon from '@mui/icons-material/Comment';

interface CatchRegistrationMoveModalProps {
  open: boolean;
  handleClose: () => void;
  selectedWeightNote: WeightNote;
  handleMove: (weightNoteId: number, isToReweighing: boolean) => Promise<void>;
  showSnackbar: (message: string, severity: string) => void;
  user: User;
}

const CatchRegistrationMoveModal: FC<CatchRegistrationMoveModalProps> = (props) => {
  const [weightNotes, setWeightNotes] = useState<WeightNote[]>([]);
  const [selectedWeightNoteId, setSelectedWeightNoteId] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [fetching, setFetching] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const confirm = useConfirm();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const fetchWeightNotes = async () => {
      setFetching(true);
      try {
        const weightNotesData = await getWeightNotesReport(props.user.userPickedHarbour, '', 1, false);
        setWeightNotes(weightNotesData);
      } catch (error) {
        props.showSnackbar('Tókst ekki að sækja vigtarnótur!', 'error');
      } finally {
        setFetching(false);
      }
    };
    if (props.open) {
      fetchWeightNotes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open, props.showSnackbar]);


  const handleMoveConfirm = async () => {
    if (!selectedWeightNoteId) return;

    confirm({
      title: 'Ertu alveg viss?',
      description: `Þú ert að færa aflaskráninguna á valda vigtarnótu`,
      confirmationText: 'Færa',
      cancellationText: 'Hætta við',
      confirmationButtonProps: { variant: 'contained', color: 'primary' },
      cancellationButtonProps: { variant: 'outlined' },
    })
      .then(async () => {
        try {
          setLoading(true);
          const selectedWeightNote = weightNotes.find(
            weightNote => weightNote.id === selectedWeightNoteId
          );

          const isToReweighing = selectedWeightNote
            ? [WeightNoteTypes.TO_REWEIGHING, WeightNoteTypes.TO_HOME_REWEIGHING, WeightNoteTypes.TO_REWEIGHTING_OVERSEAS].includes(
              selectedWeightNote.weightNoteType?.id
            )
            : false;
          await props.handleMove(selectedWeightNoteId, isToReweighing);
          props.showSnackbar('Skráning færð!', 'success');
          setLoading(false);
          props.handleClose();
        } catch (error) {
          setLoading(false);
          props.showSnackbar('Óvænt villa kom upp!', 'error');
        }
      })
      .catch(() => {
        // User canceled the action
      });
  };

  const closeHandler = () => {
    if (!loading) {
      setSelectedWeightNoteId(null);
      props.handleClose();
    }
    setSearch('');
  };

  const handleSearchChange = (newSearch: string) => {
    setSearch(newSearch);
  };

  return (
    <Dialog
      open={props.open}
      onClose={closeHandler}
      disableEscapeKeyDown={loading}
      PaperComponent={isMobile || isTablet ? MobilePaperComponent : PaperComponent}
      aria-labelledby="draggable-dialog-title"
      maxWidth="sm"
    >
      <div id="draggable-dialog-title" style={{ fontSize: '1.5em', fontWeight: 'bold', lineHeight: '1.5em', margin: '0', cursor: 'move' }}>
        Færa aflaskráningu á aðra vigtarnótu
        <IconButton size="large" sx={{ position: 'absolute', top: 0, right: 0 }} onClick={closeHandler}>
          <CancelIcon fontSize="large" />
        </IconButton>
      </div>
      <div style={{ padding: '1em' }}>
        {fetching ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <div style={{ margin: '16px 0' }}>
              <input
                type="text"
                autoFocus
                placeholder="Leita..."
                onChange={(e) => handleSearchChange(e.target.value)}
                style={{
                  width: '100%',
                  padding: '12px',
                  borderRadius: '8px',
                  border: `1px solid ${theme.palette.divider}`,
                  fontSize: '16px'
                }}
              />
            </div>
            <div style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill, minmax(400px, 1fr))',
              gap: '16px',
              maxHeight: '60vh',
              overflowY: 'auto',
              padding: '8px'
            }}>
              {weightNotes
                .filter(weightNote =>
                  !weightNote.isClosed &&
                  weightNote.id !== props.selectedWeightNote.id &&
                  (weightNote.ship?.name?.toLowerCase().includes(search.toLowerCase()) ||
                    weightNote.ship?.shipRegistrationNumber?.toString().toLowerCase().includes(search.toLowerCase()) ||
                    (dayjs(weightNote.weightNoteRegistered).format('DD.MM.YYYY').toString().toLowerCase().includes(search.toLowerCase())) ||
                    weightNote.buyer?.ssn?.toLowerCase().includes(search.toLowerCase()) ||
                    weightNote.receiver?.ssn?.toLowerCase().includes(search.toLowerCase()) ||
                    weightNote.buyer?.name?.toLowerCase().includes(search.toLowerCase()) ||
                    weightNote.receiver?.name?.toLowerCase().includes(search.toLowerCase()))
                )
                .sort((a, b) => dayjs(b.weightNoteRegistered).valueOf() - dayjs(a.weightNoteRegistered).valueOf())
                .map((weightNote) => (
                  <div
                    key={weightNote.id}
                    onClick={() => !loading && setSelectedWeightNoteId(weightNote.id)}
                    style={{
                      cursor: loading ? 'not-allowed' : 'pointer',
                      position: 'relative',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '8px',
                      padding: '16px',
                      backgroundColor: selectedWeightNoteId === weightNote.id ? theme.palette.action.selected : 'transparent',
                      borderRadius: '12px',
                      border: `2px solid ${selectedWeightNoteId === weightNote.id ? theme.palette.primary.main : theme.palette.divider}`,
                      opacity: loading ? 0.7 : 1,
                      transition: 'all 0.2s ease-in-out',
                      boxShadow: selectedWeightNoteId === weightNote.id ? '0 4px 8px rgba(0, 0, 0, 0.2)' : 'none'
                    }}
                  >
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography variant="h6" fontWeight="bold">
                        {weightNote.ship?.name + ' - ' + weightNote.ship?.shipRegistrationNumber}
                      </Typography>
                      <Typography variant="h6" color="primary" fontWeight="bold">
                        {weightNote.totalQuantity.toLocaleString('is-IS', { useGrouping: true }).replace(/,/g, '.')} kg
                      </Typography>
                    </div>
                    <Typography variant="subtitle2" color="text.secondary" fontWeight="bold">
                      {weightNote.weightNoteType.name}
                    </Typography>
                    <div style={{ display: 'flex', gap: '1em' }}>
                      <div style={{
                        flex: 1,
                        backgroundColor: theme.palette.background.paper,
                        padding: '12px',
                        borderRadius: '8px',
                        border: `1px solid ${theme.palette.divider}`,
                        boxShadow: selectedWeightNoteId === weightNote.id ? '0 4px 8px rgba(0, 0, 0, 0.2)' : '0 2px 4px rgba(0, 0, 0, 0.1)'
                      }}>
                        <Typography variant="subtitle2" color="text.primary" fontWeight="bold">
                          Kaupandi
                        </Typography>
                        <Typography variant="body2">{weightNote.buyer.name}</Typography>
                        <Typography variant="body2">{weightNote.buyer.ssn}</Typography>
                        <Typography variant="caption" display="block" color="text.secondary">
                          {weightNote.buyer.homeAddress}, {weightNote.buyer.postalCodeName}
                        </Typography>
                      </div>
                      <div style={{
                        flex: 1,
                        backgroundColor: theme.palette.background.paper,
                        padding: '12px',
                        borderRadius: '8px',
                        border: `1px solid ${theme.palette.divider}`,
                        boxShadow: selectedWeightNoteId === weightNote.id ? '0 4px 8px rgba(0, 0, 0, 0.2)' : '0 2px 4px rgba(0, 0, 0, 0.1)'
                      }}>
                        <Typography variant="subtitle2" color="text.primary" fontWeight="bold">
                          Viðtakandi
                        </Typography>
                        <Typography variant="body2">{weightNote.receiver.name}</Typography>
                        <Typography variant="body2">{weightNote.receiver.ssn}</Typography>
                        <Typography variant="caption" display="block" color="text.secondary">
                          {weightNote.receiver.homeAddress}, {weightNote.receiver.postalCodeName}
                        </Typography>
                      </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '8px' }}>
                      <Typography variant="caption" color="text.secondary" sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                        <CalendarMonthIcon fontSize="small" /> {dayjs(weightNote.weightNoteRegistered).format('DD.MM.YYYY')}
                      </Typography>
                      <Typography variant="caption" color="text.secondary" sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                        <PhishingIcon fontSize="small" /> {weightNote.gear.name}
                      </Typography>
                    </div>
                    {weightNote.comment && (
                      <Typography variant="caption" color="text.secondary" sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                        <CommentIcon fontSize="small" /> {weightNote.comment}
                      </Typography>
                    )}
                  </div>
                ))}
            </div>

            <LoadingButton
              fullWidth
              size="large"
              onClick={handleMoveConfirm}
              loading={loading}
              disabled={!selectedWeightNoteId || loading}
              color="primary"
              variant="contained"
              sx={{
                mt: 2,
                position: 'sticky',
                bottom: 0,
                boxShadow: 2
              }}
            >
              Færa á vigtarnótu
            </LoadingButton>
          </>
        )}
      </div>
    </Dialog >
  );
};

export default CatchRegistrationMoveModal;