import { useState, FC } from 'react';
import { Autocomplete, InputAdornment, TextField, CircularProgress } from '@mui/material';
import { getContacts } from '../../Services/LondunarkerfiAPIService';
import Contact from '../../Models/ContactModels/Contact';
import BusinessIcon from '@mui/icons-material/Business';
import PersonIcon from '@mui/icons-material/Person';
import { debounce } from 'lodash';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { levenshteinDistance } from './Helpers/levenshtein';
import { logError } from '../../Helpers/LogError';

interface ContactAutoCompleteProps {
  type: 'Kaupandi' | 'Seljandi' | 'Viðtakandi';
  onContactChange: (contact: Contact | null) => void;
  justClosed?: (justClosed: boolean) => void;
  selectedContact: Contact | null;
  autofocus: boolean;
  smallInputField?: boolean;
  sx?: React.CSSProperties;
}

const ContactAutoComplete: FC<ContactAutoCompleteProps> = (props) => {
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [loading, setLoading] = useState(false);
  const [noDataFound, setNoDataFound] = useState(false);
  const MIN_LENGTH_TO_CALL_API = 2;

  const fetchContacts = debounce(async (search: string): Promise<void> => {
    if (search?.length < MIN_LENGTH_TO_CALL_API) {
      setContacts([]);
      setNoDataFound(false);
      return;
    }
    setLoading(true);
    try {
      if (search.match(/^\d+$/)) {
        const contacts = await getContacts(undefined, search);
        setNoDataFound(contacts?.length === 0);
        setContacts(contacts);
        return;
      }
      const contacts = await getContacts(search);
      // Sort contacts based on similarity to search query
      const sortedContacts = contacts.sort((a, b) => {
        const distanceA = levenshteinDistance(a.name.toLowerCase(), search.toLowerCase());
        const distanceB = levenshteinDistance(b.name.toLowerCase(), search.toLowerCase());

        return distanceA - distanceB;
      });
      setNoDataFound(sortedContacts?.length === 0);
      setContacts(sortedContacts);
    } catch (error) {
      logError(error);
    } finally {
      setLoading(false);
    }
  }, 500);

  const handleContactChange = (contact: Contact | null) => {
    props.onContactChange(contact);
  };

  return (
    <Autocomplete
      disablePortal
      options={contacts}
      loading={loading}
      loadingText="Sæki..."
      noOptionsText={noDataFound ? "Ekkert fannst" : "Skrifaðu til að leita"}
      autoSelect
      autoHighlight
      onClose={() => {
        setTimeout(() => props.justClosed && props.justClosed(false), 200);
      }}
      isOptionEqualToValue={(option, value) => option?.ssn === value?.ssn}
      value={props.selectedContact}
      onChange={(event, value) => {
        handleContactChange(value);
        props.justClosed && props.justClosed(true);
      }}
      slotProps={{
        popper: {
          disablePortal: false,
        }
      }}
      onInputChange={(event, value, reason) => {
        if (reason === 'input') {
          if (value && !contacts.some(contact => {
            const optionLabel = props.smallInputField ? contact?.name : `${contact?.name}\n${contact?.ssn}\n${contact?.homeAddress}\n${contact?.postalCodeName}`;
            return optionLabel === value;
          })) {
            fetchContacts(value);
          }
        }
      }}
      getOptionLabel={(option) => {
        if (props.smallInputField) {
          return option?.name
        } else {
          return option?.name + "\n" +
            option?.ssn + "\n" +
            option?.homeAddress + "\n" +
            option?.postalCodeName
        }
      }}
      renderOption={(optionProps, option, { inputValue }) => {
        const { key, ...restOptionProps } = optionProps as { key: string } & React.HTMLAttributes<HTMLLIElement>;
        const nameMatches = match(option.name, inputValue, { insideWords: true });
        const nameParts = parse(option.name, nameMatches);

        const ssnMatches = match(option.ssn, inputValue, { insideWords: true });
        const ssnParts = parse(option.ssn, ssnMatches);

        return (
          <li key={key} {...restOptionProps}>
            <div>
              {nameParts.map((part, index) => (
                <span
                  key={index}
                  style={{
                    fontWeight: part.highlight ? 700 : 400,
                  }}
                >
                  {part.text}
                </span>
              ))}
              {" - "}
              {ssnParts.map((part, index) => (
                <span
                  key={index}
                  style={{
                    fontWeight: part.highlight ? 700 : 400,
                  }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          multiline
          rows={props.smallInputField ? 1 : 5}
          {...params}
          label={props.type}
          autoFocus={props.autofocus}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                {props.type === 'Kaupandi' ? (
                  <BusinessIcon />
                ) : props.type === 'Seljandi' ? (
                  <PersonIcon />
                ) : props.type === 'Viðtakandi' ? (
                  <PersonIcon />
                ) : (
                  <></>
                )}
              </InputAdornment>
            ),
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      sx={props.sx}
    />
  );
};

export default ContactAutoComplete;