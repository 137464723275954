import { FC, useEffect, useRef, useState } from 'react';
import { TitleFont, EditWeightNoteModalWrapper } from './EditWeightNoteModal.styled';
import { Autocomplete, Dialog, IconButton, InputAdornment, TextField, useMediaQuery, useTheme } from '@mui/material';
import { patchWeightNote } from '../../../../Services/LondunarkerfiAPIService';
import Contact from '../../../../Models/ContactModels/Contact';
import WeightNote from '../../../../Models/WeightNoteModels/WeightNote';
import Gear from '../../../../Models/WeightNoteModels/Gear';
import WeightNoteType from '../../../../Models/WeightNoteModels/WeightNoteType';
import WeightNoteSubmit from '../../../../Models/WeightNoteModels/WeightNoteSubmit';
import Landing from '../../../../Models/LandingModels/Landing';
import CommentIcon from '@mui/icons-material/Comment';
import CancelIcon from '@mui/icons-material/Cancel';
import ScaleIcon from '@mui/icons-material/Scale';
import PhishingIcon from '@mui/icons-material/Phishing';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { LoadingButton } from '@mui/lab';
import Permit from '../../../../Models/ReWeightingModels/Permit';
import User from '../../../../Models/UserModels/User';
import ContactAutoComplete from '../../../../SharedComponents/AutoComplete/ContactAutoComplete';
import WeightNoteTypes from '../../../../Constants/WeightNoteTypes';
import { MobilePaperComponent, PaperComponent } from '../../../../SharedComponents/Paper/CustomPaper';
import { logError } from '../../../../Helpers/LogError';
import LandingStatuses from '../../../../Constants/LandingStatuses';

interface EditWeightNoteModalProps {
  editWeightNoteModalOpen: boolean;
  toggleEditWeightNoteModalOpen: (inputToFocus?: string) => void;
  inputToFocus?: string;
  selectedWeightNote: WeightNote;
  selectedLanding: Landing;
  fishingGears: Gear[];
  weightNoteTypes: WeightNoteType[];
  fetchLandings: (landingId: number, weightNoteId: number) => void;
  fetchCompleteLandings: (pageNumber: number, refetching: boolean, requestedLandingId: number, requestedWeightNoteId: number) => void;
  showSnackbar: (message: string, severity: string) => void;
  permits: Permit[];
  user: User;
}

/**
 * Functional component for EditWeightNoteModal.
 * Displays a modal for editing a weightNote.
 * @param props 
 * - takes in various values used for dropDown values.
 * - takes in the selected landing and the selected weightNote.
 * - takes in a function to fetch landings.
 * @returns {JSX} - Responsible for returning the modal to edit a weightNote.
 */

const EditWeightNoteModal: FC<EditWeightNoteModalProps> = (props: EditWeightNoteModalProps) => {

  const [selectedBuyer, setSelectedBuyer] = useState<Contact | null>(null);
  const [selectedReciever, setSelectedReciever] = useState<Contact | null>(null);
  const [selectedFishingGear, setSelectedFishingGear] = useState<Gear | null>(null);
  const [selectedWeightNoteType, setSelectedWeightNoteType] = useState<WeightNoteType | null>(null);
  const [comment, setComment] = useState('');
  const [PatchBody, setPatchBody] = useState<WeightNoteSubmit | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const [autocompleteJustClosed, setAutocompleteJustClosed] = useState(false);
  const [recieverPermits, setRecieverPermits] = useState<Permit[]>([]);
  const [selectedPermit, setSelectedPermit] = useState<Permit | null>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const initiateWeightNoteFormData = () => {
      if (!props.selectedWeightNote.id) {
        return;
      }
      setSelectedBuyer(props.selectedWeightNote.buyer);
      setSelectedReciever(props.selectedWeightNote.receiver);
      setSelectedFishingGear(props.selectedWeightNote.gear);
      setSelectedWeightNoteType(props.selectedWeightNote.weightNoteType);
      setComment(props.selectedWeightNote.comment ? props.selectedWeightNote.comment : '');
      const permit = props.permits.find(permit => permit.permitId === props.selectedWeightNote?.permitId);
      // Find all permits based on the contact.id
      const matchingPermits = props.permits.filter((permit) => permit.contactId === props.selectedWeightNote.receiver.id);
      setRecieverPermits(matchingPermits);
      if (permit) {
        setSelectedPermit(permit);
      }
      else {
        setSelectedPermit(null);
      }
    }
    initiateWeightNoteFormData();
  }, [props.permits, props.selectedWeightNote, props.editWeightNoteModalOpen]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && !loading && !autocompleteJustClosed) {
        event.preventDefault();
        submitButtonRef.current?.click();
      }
    };

    document.addEventListener('keydown', handleKeyPress);
    return () => document.removeEventListener('keydown', handleKeyPress);
  }, [autocompleteJustClosed, loading]);

  const handleContactChange = (contact: Contact | null, type: 'buyer' | 'reciever' | '') => {
    if (type === 'buyer') {
      handleBuyerChange(contact);
    }
    else if (type === 'reciever') {
      handleRecieverChange(contact);
    }
  }

  const handleBuyerChange = (params: Contact | null) => {
    setSelectedBuyer(params);
    setPatchBody(prevPatchBody => ({
      ...prevPatchBody,
      buyerId: params?.id
    }));
  }

  const handleRecieverChange = (params: Contact | null) => {
    setSelectedReciever(params);
    // Find all permits based on the contact.id
    const matchingPermits = props.permits.filter((permit) => permit.contactId === params?.id);
    setRecieverPermits(matchingPermits);
    if (matchingPermits.length === 1) {
      handlePermitChange(matchingPermits[0]);
    } else {
      handlePermitChange(null);
    }
    setPatchBody(prevPatchBody => ({
      ...prevPatchBody,
      recieverId: params?.id
    }));
  }

  const handleWeightNoteTypeChange = (params: WeightNoteType | null) => {
    if (params?.id !== selectedWeightNoteType?.id) {
      setSelectedWeightNoteType(params);
    }
    if (params?.id === props.selectedWeightNote.weightNoteType?.id) {
      setPatchBody(prevPatchBody => {
        const updatedPatchBody = { ...prevPatchBody };
        delete updatedPatchBody.type;
        return updatedPatchBody;
      });
    } else {
      setPatchBody(prevPatchBody => ({
        ...prevPatchBody,
        type: params?.id
      }));
    }
  }

  const handleInputWeightNoteTypeChange = (event: React.ChangeEvent<object>, value: string) => {
    // Filter weightNoteTypes
    const filteredTypes = props.weightNoteTypes.filter((type) => {
      return type.name.toLowerCase().includes(value.toLowerCase());
    });

    if (filteredTypes?.length === 1) {
      // If there's only one matching weightNoteType, set it as the value.
      handleWeightNoteTypeChange(filteredTypes[0]);
    }
  }

  const handleFishingGearChange = (params: Gear | null) => {
    if (params?.id !== selectedFishingGear?.id) {
      setSelectedFishingGear(params);
      if (params?.id === props.selectedWeightNote.gear?.id) {
        setPatchBody(prevPatchBody => {
          const updatedPatchBody = { ...prevPatchBody };
          delete updatedPatchBody.gearId;
          return updatedPatchBody;
        });
      } else {
        setPatchBody(prevPatchBody => ({
          ...prevPatchBody,
          gearId: params?.id
        }));
      }
    }
  }

  const handleInputGearChange = (event: React.ChangeEvent<object>, value: string) => {
    // Filter gears
    const filteredGears = props.fishingGears.filter((gear) => {
      return gear.name.toLowerCase().includes(value.toLowerCase());
    });

    if (filteredGears?.length === 1) {
      // If there's only one matching gear, set it as the value.
      handleFishingGearChange(filteredGears[0]);
    }
  }

  const handleCommentChange = (comment: string) => {
    setComment(comment);
    setPatchBody(prevPatchBody => ({
      ...prevPatchBody,
      comment: comment
    }));
  }

  const handlePermitChange = (params: Permit | null) => {
    setSelectedPermit(params);
  }

  const handleEditWeightNote = () => {
    if (PatchBody) {
      if (!selectedBuyer) {
        return;
      }
      if (!selectedReciever) {
        return;
      }
      if (!selectedFishingGear || selectedFishingGear.id === undefined) {
        return;
      }
      if (!selectedWeightNoteType || selectedWeightNoteType.id === undefined) {
        return;
      }
      // always set the state of the permit since it can be a optional parameter.
      PatchBody.permitId = selectedPermit?.permitId;
      editWeightNote(PatchBody);
    }
    else {
      props.showSnackbar('Óvænt villa kom upp!', 'error');
    }
  }

  async function editWeightNote(editBody: WeightNoteSubmit) {
    try {
      setLoading(true);
      await patchWeightNote(props.selectedWeightNote.id, editBody);
      props.showSnackbar('Vigtarnóta uppfærð!', 'success');
      if (props.selectedLanding.landingStatus.id === LandingStatuses.OPEN) {
        props.fetchLandings(props.selectedLanding.id, props.selectedWeightNote.id);
      } else {
        props.fetchCompleteLandings(1, true, props.selectedLanding.id, props.selectedWeightNote.id);
      }
      props.toggleEditWeightNoteModalOpen();
      handleClose();
      setLoading(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setLoading(false);
      if (error.response && error.response.data && typeof error.response.data !== 'object') {
        const errorMsg = error.response.data;
        props.showSnackbar(errorMsg, 'error');
      }
      else {
        logError(error);
        props.showSnackbar('Óvænt villa kom upp!', 'error');
      }
    }
  }

  const handleClose = () => {
    setSelectedBuyer(props.selectedWeightNote.buyer);
    setSelectedReciever(props.selectedWeightNote.receiver);
    setSelectedFishingGear(props.selectedWeightNote.gear);
    setSelectedWeightNoteType(props.selectedWeightNote.weightNoteType);
    setComment(props.selectedWeightNote.comment ? props.selectedWeightNote.comment : '');
    setSelectedPermit(null);
    setPatchBody(undefined);
    props.toggleEditWeightNoteModalOpen();
  }

  return (
    <EditWeightNoteModalWrapper>
      <Dialog
        open={props.editWeightNoteModalOpen}
        onClose={!loading ? handleClose : undefined}
        disableEscapeKeyDown={loading}
        PaperComponent={isMobile || isTablet ? MobilePaperComponent : PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >

        <TitleFont id="draggable-dialog-title">
          Breyta vigtarnótu
          <IconButton size='large' sx={{ position: 'absolute', top: 0, right: 0 }} onClick={handleClose}>
            <CancelIcon fontSize='large' />
          </IconButton>
        </TitleFont>

        <div style={{ width: '100%', display: 'flex', flexDirection: isMobile || isTablet ? 'column' : 'row', gap: '1em' }}>
          <div style={{ width: isMobile || isTablet ? '100%' : '50%' }}>
            <ContactAutoComplete
              type='Kaupandi'
              onContactChange={(contact) => handleContactChange(contact, 'buyer')}
              justClosed={(justClosed) => setAutocompleteJustClosed(justClosed)}
              selectedContact={selectedBuyer}
              autofocus={props.inputToFocus === 'buyer'}
            />
          </div>

          <div style={{ width: isMobile || isTablet ? '100%' : '50%' }}>
            <ContactAutoComplete
              type='Viðtakandi'
              onContactChange={(contact) => handleContactChange(contact, 'reciever')}
              justClosed={(justClosed) => setAutocompleteJustClosed(justClosed)}
              selectedContact={selectedReciever}
              autofocus={props.inputToFocus === 'receiver'}
            />
          </div>
        </div>

        <Autocomplete
          disablePortal
          disabled={loading}
          onInputChange={handleInputGearChange}
          options={props.fishingGears}
          onChange={(event, value) => {
            handleFishingGearChange(value);
            setAutocompleteJustClosed(true);
          }}
          value={selectedFishingGear}
          autoHighlight
          autoSelect={true}
          onClose={() => {
            setTimeout(() => setAutocompleteJustClosed(false), 200);
          }}
          getOptionLabel={(option) => option.name || ""}
          sx={{ width: '100%' }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          slotProps={{
            popper: {
              disablePortal: false,
            }
          }}
          renderInput={(params) =>
            <TextField
              label="Veiðarfæri"
              {...params}
              InputProps={{
                ...params.InputProps,
                required: true,
                startAdornment: (
                  <InputAdornment position='start'>
                    <PhishingIcon />
                  </InputAdornment>
                )
              }}
              required
              autoFocus={props.inputToFocus === 'fishingGear'}
            />}
        />

        {selectedWeightNoteType?.id !== WeightNoteTypes.PRODUCT_ID && (
          <Autocomplete
            disablePortal
            disabled={loading}
            onInputChange={handleInputWeightNoteTypeChange}
            options={props.weightNoteTypes.filter((type) =>
              type.id !== WeightNoteTypes.FINAL_REWEIGHTING &&
              type.id !== WeightNoteTypes.FROM_REWEIGHTER &&
              type.id !== WeightNoteTypes.FROM_REWEIGHTER_OVERSEAS &&
              type.id !== WeightNoteTypes.PRODUCT_ID
            )}
            onChange={(event, value) => {
              handleWeightNoteTypeChange(value);
              setAutocompleteJustClosed(true);
            }}
            value={selectedWeightNoteType}
            autoHighlight
            onClose={() => {
              setTimeout(() => setAutocompleteJustClosed(false), 200);
            }}
            autoSelect={true}
            getOptionLabel={(option) => option.name || ""}
            sx={{ width: '100%' }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            slotProps={{
              popper: {
                disablePortal: false,
              }
            }}
            renderInput={(params) =>
              <TextField
                label="Tegund vigtarnótu"
                {...params}
                InputProps={{
                  ...params.InputProps,
                  required: true,
                  startAdornment: (
                    <InputAdornment position='start'>
                      <ScaleIcon />
                    </InputAdornment>
                  )
                }}
                required
                autoFocus={props.inputToFocus === 'weightNoteType'}
              />}
          />
        )}

        <Autocomplete
          disablePortal
          disabled={loading}
          options={recieverPermits}
          value={selectedPermit}
          autoHighlight
          onClose={() => {
            setTimeout(() => setAutocompleteJustClosed(false), 200);
          }}
          autoSelect={true}
          noOptionsText='Engin vigtunarleyfi'
          onChange={(event, value) => {
            handlePermitChange(value);
            setAutocompleteJustClosed(true);
          }}
          getOptionLabel={(option) => option.homeAdress + ' ' + option.postalCode + ' ' + option.place + ' (' + option.permitId + ' ' + option.typeOfPermitName + ' - ' + option.contactType + ')'}
          sx={{ width: '100%' }}
          slotProps={{
            popper: {
              disablePortal: false,
            }
          }}
          renderInput={(params) =>
            <TextField
              label="Vigtunarleyfi"
              {...params}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position='start'>
                    <WorkspacePremiumIcon />
                  </InputAdornment>
                )
              }}
            />}
        />

        <TextField
          label="Athugasemd"
          disabled={loading}
          onChange={(e) => handleCommentChange(e.target.value)}
          value={comment}
          InputProps={{
            inputProps: {
              maxLength: 100
            },
            startAdornment: <InputAdornment position="start"><CommentIcon /></InputAdornment>,
          }}
          autoFocus={props.inputToFocus === 'comment'}
        />

        <LoadingButton
          ref={submitButtonRef}
          size="small"
          onClick={handleEditWeightNote}
          loading={loading}
          disabled={
            (Object.keys(PatchBody || {}).length === 0 &&
              (selectedPermit?.permitId === props.selectedWeightNote?.permitId ||
                (selectedPermit?.permitId === undefined &&
                  props.selectedWeightNote?.permitId === null))) ||
            !PatchBody ||
            loading ||
            props.user?.role.id === Number(process.env.REACT_APP_ROLE_READ_ID) ||
            !selectedBuyer ||
            !selectedReciever ||
            !selectedWeightNoteType ||
            !selectedFishingGear?.id ||
            (selectedWeightNoteType.id === WeightNoteTypes.TO_REWEIGHING && !selectedPermit) ||
            (selectedWeightNoteType.id === WeightNoteTypes.TO_HOME_REWEIGHING && !selectedPermit)
          }
          variant="contained"
        >
          <span>Uppfæra vigtarnótu</span>
        </LoadingButton>
      </Dialog>
    </EditWeightNoteModalWrapper>
  );
}
export default EditWeightNoteModal;
