import { FC, useState, useEffect, useRef } from "react";
import { Avatar, Dialog, IconButton, InputAdornment, TextField, useMediaQuery, useTheme } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';
import User from "../../../../Models/UserModels/User";
import PostCatchBody from "../../../../Models/CatchRegistrationModels/PostCatchBody";
import WeightNote from "../../../../Models/WeightNoteModels/WeightNote";
import FishingStock from "../../../../Models/CatchRegistrationModels/FishingStock";
import FishingArea from "../../../../Models/CatchRegistrationModels/FishingArea";
import CatchCombination from "../../../../Models/CatchRegistrationModels/CatchCombination";
import Destiny from "../../../../Models/CatchRegistrationModels/Destiny";
import Condition from "../../../../Models/CatchRegistrationModels/Condition";
import StorageMethod from "../../../../Models/CatchRegistrationModels/StorageMethod";
import CatchRegistrationCatch from "../../../Home/Components/CatchRegistrationModal/CatchRegistrationCatch/CatchRegistrationCatch";
import CatchRegistration from "../../../../Models/CatchRegistrationModels/CatchRegistration";
import ScaleIcon from '@mui/icons-material/Scale';
import LineWeightIcon from '@mui/icons-material/LineWeight';
import PercentIcon from '@mui/icons-material/Percent';
import CommentIcon from '@mui/icons-material/Comment';
import { AddWeight, editWeight } from "../../../../Services/EndurvigtunAPIService";
import { patchWeight, postWeight } from "../../../../Services/LondunarkerfiAPIService";
import Landing from "../../../../Models/LandingModels/Landing";
import { MobilePaperComponent, PaperComponent } from "../../../../SharedComponents/Paper/CustomPaper";
import CatchRegistrationDefaults from "../../../../Constants/CatchRegistrationDefaults";
import { logError } from "../../../../Helpers/LogError";
import { Carousel } from "react-responsive-carousel";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CircleIcon from '@mui/icons-material/Circle';
import { useConfirm } from "material-ui-confirm";

interface ReWeightingModalProps {
  user: User;
  open: boolean;
  selectedWeightNote: WeightNote;
  selectedWeightItem: CatchRegistration | undefined;
  fishingStocks: FishingStock[];
  fishingArea: FishingArea[];
  storageMethods: StorageMethod[];
  destinies: Destiny[];
  conditions: Condition[];
  catchCombinations: CatchCombination[];
  toggleOpen: () => void;
  showSnackbar: (message: string, severity: string) => void;
  resetWeightItem: () => void;
  setNewWeightItem?: (weightId: number, formData?: PostCatchBody) => Promise<void>;
  selectedCarouselIndex: number | undefined;
  setIndex: (index: number | undefined) => void;
  sortedWeights: CatchRegistration[] | undefined;
  refetchLandings?: (pageNumber: number, refetching: boolean) => void;
  fetchWeightNotes?: (landing: Landing, weightNoteId: number) => Promise<WeightNote[]>;
  selectedLanding?: Landing;
}

/** 
 * Functional component for Reweighting modal.
 * @param {ReWeightingModalProps} props 
 * @returns {JSX} renders the Reweighting modal component
 * 
 * Responsible for rendering the Reweighting modal component.
*/

const ReWeightingModal: FC<ReWeightingModalProps> = (props: ReWeightingModalProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<PostCatchBody>({} as PostCatchBody);
  const [currentTotalWeight, setCurrentTotalWeight] = useState<number>(0);
  const [unGuttedQuantity, setUnGuttedQuantity] = useState<number>(0);
  const [quantity, setQuantity] = useState<number>(0);
  const [icePercentage, setIcePercentage] = useState<number>(0);
  const [declaredIcePercentage, setDeclaredIcePercentage] = useState<string>('');
  const [comment, setComment] = useState<string>('');
  const confirm = useConfirm();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const carouselRef = useRef<Carousel | null>(null);
  const nettoMagnRef = useRef<HTMLInputElement | null>(null);
  const MAXIMUM_WEIGHT = 9999999;

  useEffect(() => {
    if (props.selectedWeightItem) {
      setCurrentTotalWeight(props.selectedWeightItem.quantity);
      setQuantity(props.selectedWeightItem.quantity);
      setUnGuttedQuantity(props.selectedWeightItem.unGuttedQuantity);
      setIcePercentage(props.selectedWeightItem.icePercentage);
      setDeclaredIcePercentage(props.selectedWeightItem.declaredIcePercentage?.toString() ?? '');
      setComment(props.selectedWeightItem.userComment);
      handleChildFormChange(
        {
          unGuttedQuantity: props.selectedWeightItem.unGuttedQuantity,
          userComment: props.selectedWeightItem.userComment,
          icePercentage: props.selectedWeightItem.icePercentage,
          declaredIcePercentage: props.selectedWeightItem.declaredIcePercentage,
          weight: props.selectedWeightItem.quantity,
          fishingAreaId: props.selectedWeightItem.fishingArea.id,
          storageMethodId: props.selectedWeightItem.storageMethod.id,
          destinyId: props.selectedWeightItem.destiny.id,
          fishStockId: props.selectedWeightItem.fishingStock.id,
          conditionId: props.selectedWeightItem.condition.id,
          isUnderKilogram: props.selectedWeightItem.underKilogram
        }
      );
    } else {
      setFormData({} as CatchRegistration);
      setCurrentTotalWeight(0);
      setQuantity(0);
      setUnGuttedQuantity(0);
      setIcePercentage(0);
      setDeclaredIcePercentage('');
      setComment('');
    }
  }, [props.selectedWeightItem]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        if (submitButtonRef.current) {
          submitButtonRef.current.click();
        }
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const handleCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value?.length > 100) return;
    setComment(e.target.value);
    handleChildFormChange({ userComment: e.target.value });
  };

  const handleDeclaredIcePercentageChange = (icePercentage: string) => {
    if (icePercentage.trim() === "") {
      setDeclaredIcePercentage("");
      handleChildFormChange({ declaredIcePercentage: undefined });
      return;
    }

    if (!/^[0-9,.]*$/.test(icePercentage)) {
      return;
    }

    icePercentage = icePercentage.replace(',', '.');

    const parts = icePercentage.split('.');
    if (parts.length > 2) {
      return;
    }

    if (parts[1]?.length > 2) {
      icePercentage = `${parts[0]}.${parts[1].slice(0, 2)}`;
    }

    const inputValue = parseFloat(icePercentage);

    if (isNaN(inputValue) || inputValue < 0 || inputValue > 100) {
      return;
    }

    setDeclaredIcePercentage(icePercentage);
    handleChildFormChange({ declaredIcePercentage: inputValue });
  };

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (Number(e.target.value) < 0 || Number(e.target.value) > MAXIMUM_WEIGHT) return;
    setQuantity(Number(e.target.value));
    handleChildFormChange({ weight: Number(e.target.value) });
  };

  const handleClose = () => {
    setFormData({} as CatchRegistration);
    setQuantity(0);
    setUnGuttedQuantity(0);
    setIcePercentage(0);
    setDeclaredIcePercentage("");
    setComment('');
    props.resetWeightItem();
    props.toggleOpen();
  };

  const handleReset = async () => {
    await props.setNewWeightItem?.(props.selectedWeightItem?.id ?? 0);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const submittedFormData = formData;
      submittedFormData.amount = formData.weight;
      if (props.user?.isReweighingUser) {
        submittedFormData.isFromReWeighter = true;
        await AddWeight(submittedFormData, props.selectedWeightNote.id);
        if (props.refetchLandings) {
          props.refetchLandings(1, true);
        }
      } else {
        submittedFormData.isFromReWeighter = true;
        await postWeight(submittedFormData, props.selectedWeightNote.id);
        if (props.fetchWeightNotes && props.selectedLanding) {
          props.fetchWeightNotes(props.selectedLanding, props.selectedWeightNote.id);
        }
      }
      props.showSnackbar('Vigtun skráð', 'success');
      setLoading(false);
      handleClose();
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    catch (error: any) {
      setLoading(false);
      if (error.response && error.response.data && typeof error.response.data !== 'object') {
        const errorMsg = error.response.data;
        props.showSnackbar(errorMsg, 'error');

      }
      else {
        logError(error);
        props.showSnackbar('Óvænt villa kom upp!', 'error');
      }
    }
  };

  const handleUpdate = async () => {
    if (props.selectedWeightItem) {
      await editTheWeight(props.selectedWeightItem.id, formData);
    }
    setTimeout(() => {
      // focus carousel after submission so users can use arrows.
      if (carouselRef.current) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const wrapper = (carouselRef.current as any).carouselWrapperRef;
        if (wrapper) {
          (wrapper as HTMLElement).focus();
        }
      }
    }, 0);
  };

  async function editTheWeight(weightId: number, editBody: PostCatchBody) {
    try {
      setLoading(true);
      editBody.isFromReWeighter = true;
      if (props.user?.isReweighingUser) {
        if (!editBody.declaredIcePercentage) {
          editBody.declaredIcePercentage = 0;
        }
        if (!editBody.unGuttedQuantity) {
          editBody.unGuttedQuantity = 0;
        }
        await editWeight(weightId, editBody);
        if (props.refetchLandings) {
          props.refetchLandings(1, true);
        }
      } else {
        await patchWeight(weightId, editBody);
        if (props.fetchWeightNotes && props.selectedLanding) {
          props.fetchWeightNotes(props.selectedLanding, props.selectedWeightNote.id);
        }
      }
      props.showSnackbar('Vigtun uppfærð', 'success');
      setLoading(false);
      handleReset();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setLoading(false);
      if (error.response && error.response.data && typeof error.response.data !== 'object') {
        const errorMsg = error.response.data;
        props.showSnackbar(errorMsg, 'error');
      }
      else {
        logError(error);
        props.showSnackbar('Óvænt villa kom upp!', 'error');
      }
    }
  }

  const handleChildFormChange = (updatedFormData: PostCatchBody) => {
    setFormData((prevForm) => ({
      ...prevForm,
      ...updatedFormData
    }));
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleUnguttedChange = (e: any) => {
    if (Number(e.target.value) < 0 || Number(e.target.value) > MAXIMUM_WEIGHT) return;
    setUnGuttedQuantity(Number(e.target.value));
    handleChildFormChange({ unGuttedQuantity: Number(e.target.value) });
  };

  const handleCarouselChange = (newIndex: number) => {
    props.setIndex(newIndex);
    setTimeout(() => {
      nettoMagnRef.current?.focus();
      nettoMagnRef.current?.select();
    }, 0);
    setFormData({});
  };

  const closeHandler = () => {
    if (!loading) {
      if (props.selectedWeightItem) {
        if (props.selectedWeightItem) {
          const hasChanges =
            props.selectedWeightItem.quantity !== formData.weight ||
            props.selectedWeightItem.unGuttedQuantity !== formData.unGuttedQuantity ||
            props.selectedWeightItem.icePercentage !== formData.icePercentage ||
            props.selectedWeightItem.declaredIcePercentage !== formData.declaredIcePercentage ||
            props.selectedWeightItem.userComment !== formData.userComment ||
            props.selectedWeightItem.fishingArea.id !== formData.fishingAreaId ||
            props.selectedWeightItem.storageMethod.id !== formData.storageMethodId ||
            props.selectedWeightItem.destiny.id !== formData.destinyId ||
            props.selectedWeightItem.fishingStock.id !== formData.fishStockId ||
            props.selectedWeightItem.condition.id !== formData.conditionId ||
            props.selectedWeightItem.underKilogram !== formData.isUnderKilogram;

          return hasChanges ? confirmClose() : handleClose();
        }
      } else {
        // form initializes with 3 formData keys. If there are more keys, the user has made changes.
        return Object.keys(formData)?.length > 3 ? confirmClose() : handleClose();
      }
    }
    return undefined;
  };

  const confirmClose = () => {
    confirm({
      title: 'Villtu loka glugganum?',
      description: `Þú ert með óvistaðar breytingar.`,
      confirmationText: 'Loka',
      cancellationText: 'Hætta við',
      confirmationButtonProps: { variant: 'contained', color: 'primary' },
      cancellationButtonProps: { variant: 'outlined' },
    })
      .then(() => handleClose())
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => { });
  };

  return (
    <Dialog
      open={props.open}
      onClose={closeHandler}
      PaperComponent={isMobile || isTablet ? MobilePaperComponent : PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <div
        id="draggable-dialog-title"
        style={{
          fontWeight: 'bold',
          cursor: 'move',
          width: '100%'
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', alignItems: 'center', marginTop: '0.5em' }}>
          <div style={{ flex: 1, width: '100%' }}>
            <Carousel
              ref={carouselRef}
              useKeyboardArrows
              labels={{ leftArrow: 'Fyrri aflaskráning', rightArrow: 'Næsta aflaskráning', item: 'Vigtun' }}
              autoPlay={false}
              infiniteLoop
              showStatus={false}
              showThumbs={false}
              showIndicators={!isMobile && !isTablet}
              selectedItem={props.selectedCarouselIndex}
              onChange={handleCarouselChange}
              renderIndicator={(onClickHandler, isSelected, index, label) => {
                const defStyle = { color: "#CCCCCC", cursor: "pointer", padding: "0.3em", width: '50%' };
                const style = isSelected && props.selectedWeightItem
                  ? { ...defStyle, color: "black" }
                  : { ...defStyle };
                return (
                  <span
                    style={style}
                    onClick={(e) => {
                      onClickHandler(e);
                      if (props.sortedWeights?.length === 1) {
                        handleCarouselChange(0);
                      }
                    }}
                    onKeyDown={(e) => {
                      onClickHandler(e);
                    }}
                    key={index}
                    role="button"
                    tabIndex={0}
                    aria-label={`${label} ${index + 1}`}
                  >
                    <CircleIcon sx={{ fontSize: '0.7em' }} />
                  </span>
                );
              }}
              renderArrowPrev={(onClickHandler, hasPrev, label) =>
                hasPrev && (
                  <IconButton onClick={onClickHandler} title={label} style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', left: 0, zIndex: 1, backgroundColor: 'lightslategray' }}>
                    <ArrowBackIcon sx={{ color: 'white' }} />
                  </IconButton>
                )
              }
              renderArrowNext={(onClickHandler, hasNext, label) =>
                hasNext && (
                  <IconButton onClick={onClickHandler} title={label} style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: '0.5em', zIndex: 1, backgroundColor: 'lightslategray' }}>
                    <ArrowForwardIcon sx={{ color: 'white' }} />
                  </IconButton>
                )
              }
            >
              {props.sortedWeights?.map((weight) => (
                <div key={weight.id} style={{ marginBottom: '2em' }}>
                  {props.selectedWeightItem ? (
                    <>
                      {isMobile || isTablet ? (
                        <>
                          <div style={{ fontSize: '1.5em' }}>Vigtun</div>
                          <div style={{ fontSize: '1.5em' }}>{'#' + weight.id}</div>
                        </>
                      ) : (
                        <div style={{ fontSize: '1.5em' }}>Vigtun {'#' + weight.id}</div>
                      )}
                    </>
                  ) : (
                    <div style={{ fontSize: '1.5em' }}>Vigtun</div>
                  )}
                </div>
              ))}
            </Carousel>
          </div>

          <div style={{ display: 'flex', flexDirection: isMobile || isTablet ? 'column' : 'row', gap: isMobile || isTablet ? '1em' : '0em', justifyContent: 'center' }}>
            <Avatar
              sx={{
                bgcolor: theme.palette.mode === "dark" ? "#272727" : 'white',
                fontSize: 16,
                color: theme.palette.text.primary,
                border: '1px solid #cdcdcd',
                fontWeight: '500',
                height: 40,
                width: 150,
                borderRadius: '1em'
              }}
              variant="rounded"
            >
              {currentTotalWeight + " kg" || "0 kg"}
            </Avatar>
          </div>
          <IconButton sx={{ position: 'absolute', top: 0, right: 0 }} onClick={closeHandler}>
            <CancelIcon fontSize='large' />
          </IconButton>
        </div>
      </div>
      <CatchRegistrationCatch
        handleFormChange={handleChildFormChange}
        allowedCombinations={props.catchCombinations}
        weight={props.selectedWeightItem}
        selectedWeightNote={props.selectedWeightNote}
        fishAreas={props.fishingArea}
        storageMethods={props.storageMethods}
        destinies={props.destinies}
        fishingStocks={props.fishingStocks}
        conditions={props.conditions}
        disabled={props.selectedWeightNote.isClosed || loading}
        containsExported={props.selectedWeightNote.weights?.some(weight => weight.destiny?.id === CatchRegistrationDefaults.DESTINY_EXPORTED_ID) ?? false}
      />
      <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', width: '100%', gap: '1em' }}>
        <TextField
          label="Nettómagn"
          required
          inputRef={nettoMagnRef}
          autoFocus={props.selectedWeightItem ? true : false}
          onFocus={(e) => {
            if (props.selectedWeightItem) {
              setTimeout(() => {
                e.target.select();
              }, 0); // Delays execution slightly to ensure the value is set. When focus is attained value is not there
            }
          }}
          type="number"
          variant="outlined"
          value={quantity || ''}
          onChange={handleQuantityChange}
          onKeyDown={(e) => {
            if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+" || e.key === ".") {
              e.preventDefault()
            }
          }}
          disabled={props.selectedWeightNote.isClosed || loading}
          InputProps={{
            inputProps: {
              min: 0,
              max: MAXIMUM_WEIGHT
            },
            startAdornment: <InputAdornment position="start"><ScaleIcon color="primary" /></InputAdornment>,
          }}
          style={{ width: isMobile || isTablet ? '100%' : '48%' }}
        />
        <TextField
          label="Óslægt magn"
          type="number"
          variant="outlined"
          value={unGuttedQuantity || ''}
          onChange={handleUnguttedChange}
          onKeyDown={(e) => {
            if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+" || e.key === ".") {
              e.preventDefault()
            }
          }}
          disabled={props.selectedWeightNote.isClosed || loading}
          InputProps={{
            inputProps: {
              min: 0,
              max: MAXIMUM_WEIGHT
            },
            startAdornment: <InputAdornment position="start"><LineWeightIcon color="primary" /></InputAdornment>,
          }}
          style={{ width: isMobile || isTablet ? '100%' : '48%' }}
        />
        <TextField
          type="number"
          disabled={true}
          id="icePercentage"
          label="Ís (%)"
          variant="outlined"
          value={icePercentage ? icePercentage : icePercentage !== 0 ? icePercentage : ''}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PercentIcon color='primary' />
              </InputAdornment>
            )
          }}
          style={{ width: isMobile || isTablet ? '100%' : '48%' }}
        />
        <TextField
          type="text"
          inputMode="decimal"
          disabled={loading || props.selectedWeightNote.isClosed}
          id="declaredIcePercentage"
          label="Uppgefin ís (%)"
          variant="outlined"
          value={declaredIcePercentage ?? ''}
          onChange={(e) => handleDeclaredIcePercentageChange(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+") {
              e.preventDefault()
            }
          }}
          InputProps={{
            inputProps: {
              min: 0,
              max: 100
            },
            startAdornment: (
              <InputAdornment position="start">
                <PercentIcon color='primary' />
              </InputAdornment>
            )
          }}
          style={{ width: isMobile || isTablet ? '100%' : '48%' }}
        />
        <TextField
          type="string"
          disabled={props.selectedWeightNote.isClosed || loading}
          id="comment"
          label="Athugasemd"
          variant="outlined"
          onChange={handleCommentChange}
          value={comment ?? ''}
          InputProps={{
            inputProps: {
              maxLength: 100
            },
            startAdornment: (
              <InputAdornment position="start">
                <CommentIcon color='primary' />
              </InputAdornment>
            )
          }}
          style={{ width: '100%' }}
        />
      </div>
      <div>
        {props.selectedWeightItem ? (
          <LoadingButton
            fullWidth
            ref={submitButtonRef}
            size="small"
            onClick={handleUpdate}
            loading={loading}
            disabled={
              loading ||
              props.selectedWeightNote.isClosed ||
              !formData.fishingAreaId ||
              !formData.storageMethodId ||
              !formData.destinyId ||
              !formData.fishStockId ||
              !formData.conditionId ||
              !formData.weight ||
              (props.selectedWeightItem.quantity === formData.weight &&
                props.selectedWeightItem.unGuttedQuantity === formData.unGuttedQuantity &&
                props.selectedWeightItem.icePercentage === formData.icePercentage &&
                props.selectedWeightItem.declaredIcePercentage === formData.declaredIcePercentage &&
                props.selectedWeightItem.userComment === formData.userComment &&
                props.selectedWeightItem.fishingArea.id === formData.fishingAreaId &&
                props.selectedWeightItem.storageMethod.id === formData.storageMethodId &&
                props.selectedWeightItem.destiny.id === formData.destinyId &&
                props.selectedWeightItem.fishingStock.id === formData.fishStockId &&
                props.selectedWeightItem.condition.id === formData.conditionId &&
                props.selectedWeightItem.underKilogram === formData.isUnderKilogram)
            }
            endIcon={<SaveIcon />}
            color="primary"
            variant="contained"
          >
            <span>Uppfæra</span>
          </LoadingButton>
        ) : (
          <LoadingButton
            fullWidth
            ref={submitButtonRef}
            size="small"
            onClick={handleSubmit}
            loading={loading}
            disabled={
              loading ||
              props.selectedWeightNote.isClosed ||
              !formData.fishingAreaId ||
              !formData.storageMethodId ||
              !formData.destinyId ||
              !formData.fishStockId ||
              !formData.conditionId ||
              !formData.weight
            }
            endIcon={<CheckIcon />}
            color="success"
            variant="contained"
          >
            <span>Staðfesta</span>
          </LoadingButton>
        )}
      </div>
    </Dialog>
  );
};

export default ReWeightingModal;