import { FC } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, styled } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import CloseIcon from '@mui/icons-material/Close';
import EmailIcon from '@mui/icons-material/Email';
import VisibilityIcon from '@mui/icons-material/Visibility';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    minWidth: '500px',
    maxWidth: '600px'
  },
  '& .MuiDialogTitle-root': {
    padding: theme.spacing(1, 2)
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    marginTop: theme.spacing(1)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1, 2),
    gap: theme.spacing(1)
  },
  '& .MuiButton-root': {
    minHeight: '36px',
    textTransform: 'none'
  }
}));

interface CloseWeightNoteModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (actionType: 'close' | 'print' | 'send') => void;
  selectedWeightNoteId: number;
}

const CloseWeightNoteModal: FC<CloseWeightNoteModalProps> = (props) => {
  return (
    <StyledDialog open={props.open} onClose={props.onClose}>
      <DialogTitle>Ertu alveg viss?</DialogTitle>
      <DialogContent>
        Þú ert að fara loka vigtarnótu nr: {props.selectedWeightNoteId}.
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.onClose}
          variant="outlined"
          size="small"
          startIcon={<CloseIcon />}
        >
          Hætta við
        </Button>
        <Button
          onClick={() => props.onConfirm('print')}
          variant="contained"
          color="primary"
          size="small"
          startIcon={<VisibilityIcon />}
        >
          Loka og skoða
        </Button>
        <Button
          onClick={() => props.onConfirm('send')}
          variant="contained"
          color="secondary"
          size="small"
          startIcon={<EmailIcon />}
        >
          Loka og Senda
        </Button>
        <Button
          onClick={() => props.onConfirm('close')}
          variant="contained"
          color="success"
          size="small"
          startIcon={<LockIcon />}
        >
          Loka
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default CloseWeightNoteModal;
