import React, { useState, useEffect, FC, Fragment } from 'react';
import { getCatchNewsReport, getLandingById } from '../../../../../Services/LondunarkerfiAPIService';
import { DataGrid, GridColDef, GridToolbarContainer } from '@mui/x-data-grid';
import User from '../../../../../Models/UserModels/User';
import CatchNews from '../../../../../Models/ReportModels/CatchNews';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/is';
import Ship from '../../../../../Models/ShipModels/Ship';
import Contact from '../../../../../Models/ContactModels/Contact';
import ExcelGenerator from '../../../../ExcelGenerator/ExcelGenerator';
import ContactAutoComplete from '../../../../AutoComplete/ContactAutoComplete';
import ShipAutoComplete from '../../../../AutoComplete/ShipAutoComplete';
import Landing from '../../../../../Models/LandingModels/Landing';
import { logError } from '../../../../../Helpers/LogError';
import API_PAGINATION_SIZE from '../../../../../Constants/PaginationSize';

interface CatchNewsReportsProps {
  user: User;
  toggleOpen: () => void;
  setSelectedLanding: (landing: Landing) => void;
}

const CatchNewsReport: FC<CatchNewsReportsProps> = (props: CatchNewsReportsProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dateFrom, setDateFrom] = useState<Dayjs | null>(dayjs().subtract(30, 'day'));
  const [dateTo, setDateTo] = useState<Dayjs | null>(dayjs());
  const [catchNews, setCatchNews] = useState<CatchNews[]>([]);
  const [ship, setShip] = useState<Ship | null>(null);
  const [selectedReciever, setSelectedReciever] = useState<Contact | null>(null);
  const [selectedBuyer, setSelectedBuyer] = useState<Contact | null>(null);
  const [selectedSeller, setSelectedSeller] = useState<Contact | null>(null);
  const [catchNewsReport, setCatchNewsReport] = useState<CatchNews[]>([]);
  const [reportLoading, setReportLoading] = useState<boolean>(false);
  const SCROLL_TOLERANCE = 5;

  useEffect(() => {
    fetchCathes(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFrom, dateTo, ship, selectedBuyer, selectedReciever, selectedSeller]);

  const fetchCathes = async (page: number) => {
    try {
      setLoading(true);
      if (props.user?.userPickedHarbour) {
        const formattedDateFrom = dateFrom?.format('DD.MM.YYYY');
        const formattedDateTo = dateTo?.format('DD.MM.YYYY');
        const response = await getCatchNewsReport(
          props.user?.userPickedHarbour,
          page,
          formattedDateFrom,
          formattedDateTo,
          ship?.shipRegistrationNumber,
          selectedBuyer?.ssn,
          selectedReciever?.ssn,
          selectedSeller?.ssn);
        setCatchNews((prevWeightNotes) => (page === 1 ? response : [...prevWeightNotes, ...response]));
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      logError(error);
    }
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (e.currentTarget) {
      const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;
      const bottom = Math.abs(scrollHeight - scrollTop - clientHeight) <= SCROLL_TOLERANCE;
      if (bottom && !loading) {
        fetchCathes(Math.round(catchNews?.length / API_PAGINATION_SIZE) + 1);
      }
    }
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', type: 'number' },
    { field: 'landingId', headerName: 'Löndunar nr.', type: 'number', width: 150, resizable: false },
    { field: 'shipName', headerName: 'Skip', width: 150, resizable: false },
    { field: 'shipRegistrationNumber', headerName: 'Skip nr.', type: 'number', width: 120, resizable: false },
    { field: 'gearName', headerName: 'Veiðarfæri', width: 140, resizable: false },
    { field: 'fishName', headerName: 'Fisktegund', width: 140, resizable: false },
    { field: 'countFish', headerName: 'Magn', type: 'number', width: 110, resizable: false },
  ];

  const handleDateFromChange = (date: Dayjs | null) => {
    setDateFrom(date);
  };

  const handleDateToChange = (date: Dayjs | null) => {
    setDateTo(date);
  };

  const handleShipChange = (selectedShip: Ship | null) => {
    setShip(selectedShip);
  };

  const handleBuyerChange = (params: Contact | null) => {
    setSelectedBuyer(params);
  };

  const handleRecieverChange = (params: Contact | null) => {
    setSelectedReciever(params);
  };

  const handleSellerChange = (params: Contact | null) => {
    setSelectedSeller(params);
  };

  const fetchReportData = async () => {
    try {
      setReportLoading(true);
      const reportData = await getCatchNewsReport(
        props.user?.userPickedHarbour ?? 0,
        undefined,
        dateFrom?.format('DD.MM.YYYY'),
        dateTo?.format('DD.MM.YYYY'),
        ship?.shipRegistrationNumber,
        selectedBuyer?.ssn,
        selectedReciever?.ssn,
        selectedSeller?.ssn
      );
      setReportLoading(false);
      return reportData;
    } catch (error) {
      setReportLoading(false);
      logError(error);
      return [];
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchReportData();
      setCatchNewsReport(data);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFrom, dateTo, ship, selectedBuyer, selectedReciever, selectedSeller]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <ExcelGenerator data={catchNewsReport} disabled={reportLoading} fileName='aflafrettir' />
      </GridToolbarContainer>
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleLandingSelection = async (row: any) => {
    const landing = await getLandingById(row.landingId);
    props.setSelectedLanding(landing);
    props.toggleOpen();
  };

  return (
    <Fragment>
      <div style={{ width: '100%', display: 'flex', gap: '1em', flexWrap: 'wrap' }}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='is'>
          <DatePicker
            sx={{ flex: '1', minWidth: '15em' }}
            disabled={loading}
            label='Dagsetning frá'
            value={dateFrom}
            onChange={(newValue: Dayjs | null) => handleDateFromChange(dayjs(newValue))}
            format="DD.MM.YYYY"
            maxDate={dayjs()}
          />
          <DatePicker
            sx={{ flex: '1', minWidth: '15em' }}
            disabled={loading}
            label='Dagsetning til'
            value={dateTo}
            onChange={(newValue: Dayjs | null) => handleDateToChange(dayjs(newValue))}
            format="DD.MM.YYYY"
            maxDate={dayjs()}
          />
        </LocalizationProvider>
        <ShipAutoComplete onShipChange={handleShipChange} selectedShip={ship} autofocus={false} sx={{ flex: 1, minWidth: '15em' }} />
        <ContactAutoComplete type='Kaupandi' smallInputField={true} onContactChange={handleBuyerChange} selectedContact={selectedBuyer} autofocus={false} sx={{ flex: '1', minWidth: '15em' }} />
        <ContactAutoComplete type='Viðtakandi' smallInputField={true} onContactChange={handleRecieverChange} selectedContact={selectedReciever} autofocus={false} sx={{ flex: '1', minWidth: '15em' }} />
        <ContactAutoComplete type='Seljandi' smallInputField={true} onContactChange={handleSellerChange} selectedContact={selectedSeller} autofocus={false} sx={{ flex: '1', minWidth: '15em' }} />
      </div>
      <div style={{ overflowY: 'auto' }} onScroll={handleScroll}>
        <DataGrid
          rows={catchNews}
          columns={columns}
          loading={loading}
          autoHeight
          columnVisibilityModel={{ id: false }}
          onRowClick={(row) => { handleLandingSelection(row.row) }}
          slots={{
            toolbar: CustomToolbar,
          }}
        />
      </div>
    </Fragment >
  );
};

export default CatchNewsReport;
