import { FC, useState } from "react";
import {
  Dialog,
  FormControlLabel,
  IconButton,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
  Box,
  Tooltip,
  Divider,
  CircularProgress,
} from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { MobilePaperComponent, PaperComponent } from "../../../Paper/CustomPaper";
import User from "../../../../Models/UserModels/User";
import { UpdateUserSettings } from "../../../../Services/LondunarkerfiAPIService";

interface UserSettingsModalProps {
  open: boolean;
  toggleOpen: () => void;
  user: User;
}

const UserSettingsModal: FC<UserSettingsModalProps> = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const [loading, setLoading] = useState<{ [key: string]: boolean }>({
    wantsToBeginWithVehicle: false,
    wantsToPinVehicle: false,
  });

  const handleClose = () => props.toggleOpen();

  const handleToggle = async (key: keyof User["settings"]) => {
    setLoading((prev) => ({ ...prev, [key]: true }));
    const updatedSettings = {
      ...props.user.settings,
      [key]: !props.user.settings[key],
    };

    try {
      await UpdateUserSettings(updatedSettings);
      props.user.settings[key] = updatedSettings[key];
    } catch (error) {
      console.error("Failed to update user settings:", error);
    } finally {
      setLoading((prev) => ({ ...prev, [key]: false }));
    }
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      PaperComponent={isMobile || isTablet ? MobilePaperComponent : PaperComponent}
      aria-labelledby="draggable-dialog-title"
      maxWidth={'sm'}
    >
      <Box id="draggable-dialog-title"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor: 'move',
        }}
      >
        <Typography variant="h5" component="h2" fontWeight="bold">
          Notendastillingar
        </Typography>
        <IconButton onClick={handleClose} size="large">
          <CancelIcon />
        </IconButton>
      </Box>
      <Divider />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <FormControlLabel
            control={
              <Switch
                checked={props.user.settings.wantsToBeginWithVehicle}
                onChange={() => handleToggle("wantsToBeginWithVehicle")}
                color="primary"
                disabled={loading.wantsToBeginWithVehicle}
              />
            }
            label="Byrja skráningu á flutningstæki"
          />
          {loading.wantsToBeginWithVehicle && <CircularProgress size={20} />}
          <Tooltip title="Stillir kerfið þannig að aflaskráning byrjar á skráningu flutningstækis í stað veiðistofns.">
            <HelpOutlineIcon color="action" sx={{ fontSize: 20 }} />
          </Tooltip>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <FormControlLabel
            control={
              <Switch
                checked={props.user.settings.wantsToPinVehicle}
                onChange={() => handleToggle("wantsToPinVehicle")}
                color="primary"
                disabled={loading.wantsToPinVehicle}
              />
            }
            label="Halda ökutæki föstu á milli vigtana"
          />
          {loading.wantsToPinVehicle && <CircularProgress size={20} />}
          <Tooltip title="Ef flutningstæki er skráð á vigtanótu mun það halda sér fyrir aðrar aflaskráningar undir sömu nótu.">
            <HelpOutlineIcon color="action" sx={{ fontSize: 20 }} />
          </Tooltip>
        </Box>
      </Box>
    </Dialog>
  );
};

export default UserSettingsModal;